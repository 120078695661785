<template>
  <div class="vaccination-sets">
    <base-loading :waitFlg="waitFlg" />
    <div class="wrapper">
      <div class="title">予防接種</div>
      <div class="contents">
        <div class="area bulk" v-if="lookOnlyFlg === 0">
          <div
            class="buttons default"
            v-show="!mailFlg && !printFlg && !csvFlg"
          >
            <div class="button">
              <base-button-register
                :text="'CSV出力'"
                :styles="{ width: '140px' }"
                @click="toCsvMode"
              />
            </div>
            <div class="button">
              <base-button-register
                :text="'一括メール送信'"
                :styles="{ width: '160px' }"
                @click="toMailMode"
              />
            </div>
            <div class="button">
              <base-button-border-orange
                :styles="{ width: '140px' }"
                @click="toPrintMode"
                >一括宛名印刷</base-button-border-orange
              >
            </div>
          </div>
          <div class="buttons csv" v-show="csvFlg">
            <div class="button">
              <base-button-small-white @click="csvFlg = false"
                >キャンセル</base-button-small-white
              >
            </div>
            <div class="button">
              <base-button-register
                :text="'ダウンロード'"
                :disabled="checkedChecks.length === 0"
                :styles="{ width: '130px' }"
                @click="download"
              />
            </div>
          </div>
          <div class="buttons mail" v-show="mailFlg">
            <div class="button">
              <base-button-small-white @click="mailFlg = false"
                >キャンセル</base-button-small-white
              >
            </div>
            <div class="button">
              <base-button-register
                :text="'メール作成'"
                :disabled="checkedChecks.length === 0"
                :styles="{ width: '130px' }"
                @click="mailPopupFlg = true"
              />
            </div>
          </div>
          <div class="buttons print" v-show="printFlg">
            <div class="button">
              <base-button-small-white @click="printFlg = false"
                >キャンセル</base-button-small-white
              >
            </div>
            <div class="button">
              <base-button-border-orange
                :disabled="checkedChecks.length === 0"
                :styles="{ width: '120px' }"
                @click="printPopupFlg = true"
                >宛名印刷</base-button-border-orange
              >
            </div>
          </div>
        </div>
        <div class="area search">
          <search-area-detail
            v-if="detailSearchFlg"
            :searchButtonFlg="true"
            :searchConditions="searchConditions"
            :selectBoxData="detailSelectBoxData"
            :waitFlg="waitFlg"
            @add-search-condition="addSearchCondition"
            @clear="detailSearchClear($event)"
            @hide-detail="toggleSearch"
            @input-text="detailSearchInput($event, 'text')"
            @input-text2="detailSearchInput($event, 'text2')"
            @input-text3="detailSearchInput($event, 'text3')"
            @search="resetAndGetData"
            @select="setSelectId"
            @select-tag="selectOwnerTag"
            @toggle-tag-option="toggleOwnerTagSearchOption"
            @trash="deleteSearchCondition"
          />
          <search-area-simple
            v-else
            :searchForms="simpleSearchForms"
            :toggleButtonFlg="true"
            :searchButtonFlg="true"
            :waitFlg="waitFlg"
            @input="simpleSearchInput($event)"
            @clear="simpleSearchClear($event)"
            @toggle="toggleSearch"
            @search="resetAndGetData"
          />
        </div>
        <div class="area counts-sort">
          <div class="left">
            <div v-if="mailFlg || printFlg || csvFlg" class="checkbox">
              <base-check-box :isChecked="allFlg" @input="checkAll" />
            </div>
            <div class="counts">検索結果：{{ hitAllDataCounts }}件</div>
          </div>
          <div class="right">
            <div class="label">並び替え</div>
            <div class="select-box sort">
              <base-select-box :selectData="sortTypes" v-model="sortTypeId" />
            </div>
            <div class="select-box order">
              <base-select-box :selectData="orderTypes" v-model="orderTypeId" />
            </div>
          </div>
        </div>
        <div class="area list">
          <vaccination-set-list
            :vaccinationSets="vaccinationSets"
            :checks="checks"
            :mailFlg="mailFlg"
            :printFlg="printFlg"
            :csvFlg="csvFlg"
            @click-patient="goToOwnerPage"
            @click-antibody="openAntibodiesPopup"
            @click-reminder="openRemindersPopup"
            @check="check"
            @scroll-bottom="getNextData"
          />
        </div>
      </div>
    </div>
    <announce-popup
      v-if="popupFlg"
      :type="popup.type"
      :title="popup.title"
      :buttons="popup.buttons"
      @close="popupFlg = false"
      >{{ popup.message }}</announce-popup
    >
    <vaccination-mail-popup
      v-if="mailPopupFlg"
      :reminderVaccinationSets="reminderVaccinationSets"
      @close="mailPopupFlg = false"
      @updated="mailUpdated"
    />
    <print-address-sheet-popup
      v-if="printPopupFlg"
      :reminderVaccinationSets="reminderVaccinationSets"
      @close="printPopupFlg = false"
      @updated="printUpdated"
    />
    <antibodies-popup
      v-if="antibodiesPopupFlg"
      :patientName="targetVaccinationSet.patient.name"
      :patientVaccines="targetVaccinationSet.patientVaccines"
      :antibodies="targetVaccinationSet.antibodies"
      :antibodyTypes="targetVaccinationSet.antibodyTypes"
      @close="antibodiesPopupFlg = false"
      @updated="antibodyUpdated"
    />
    <vaccine-reminders-popup
      v-if="remindersPopupFlg"
      :patientId="targetVaccinationSet.patientId"
      :patientName="targetVaccinationSet.patient.name"
      :patientVaccines="targetVaccinationSet.patientVaccines"
      :vaccineReminders="targetVaccinationSet.vaccineReminders"
      @close="remindersPopupFlg = false"
      @updated="reminderUpdated"
    />
    <unsaved-leave-popup
      v-bind="{ layerNumber: 3, ...(printPopupFlg && { popupType: 'print' }) }"
    />
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import AntibodiesPopup from '@/components/popups/AntibodiesPopup'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import PrintAddressSheetPopup from '@/components/popups/PrintAddressSheetPopup'
import SearchAreaDetail from '@/components/parts/molecules/SearchAreaDetail'
import SearchAreaSimple from '@/components/parts/molecules/SearchAreaSimple'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import VaccinationMailPopup from '@/components/popups/VaccinationMailPopup'
import VaccinationSetList from '@/components/parts/organisms/VaccinationSetList'
import VaccineRemindersPopup from '@/components/popups/VaccineRemindersPopup'
import moment from 'moment'
import { antibodyTypesEnToJp, mediaEnToJp } from '@/utils/vaccine_utils'
import { calcAge, makeBirthday } from '@/utils/patient_info'
import { downloadCsv } from '@/utils/csv'
import { makeRepeatPeriods } from '@/utils/vaccine_utils'
import { mapGetters } from 'vuex'
import { toHanKakuId } from '@/utils/convert_string'

export default {
  name: 'VaccinationSets',

  components: {
    AnnouncePopup,
    AntibodiesPopup,
    BaseButtonBorderOrange,
    BaseButtonRegister,
    BaseButtonSmallWhite,
    BaseCheckBox,
    BaseLoading,
    BaseSelectBox,
    PrintAddressSheetPopup,
    SearchAreaDetail,
    SearchAreaSimple,
    UnsavedLeavePopup,
    VaccinationMailPopup,
    VaccinationSetList,
    VaccineRemindersPopup
  },

  data() {
    return {
      vaccinationSets: [],
      checks: [],
      mailFlg: false,
      printFlg: false,
      csvFlg: false,
      defaultPeriodStart: moment()
        .subtract(1, 'month')
        .format('YYYYMMDD'),
      defaultPeriodEnd: moment().format('YYYYMMDD'),
      detailSearchFlg: false,
      searchConditions: [
        { id: 1, selectId: 0, text: '', text2: '', text3: '' }
      ],
      detailSelectBoxData: [
        { id: 1, name: '飼主ID', type: 'textBox' },
        { id: 2, name: '飼主名', type: 'textBox' },
        { id: 14, name: '飼主タグ', type: 'tagsSelect' },
        { id: 3, name: '患者ID', type: 'textBox' },
        { id: 4, name: '患者名', type: 'textBox' },
        { id: 5, name: '患者の種別', type: 'selectBox' },
        { id: 6, name: '患者の品種', type: 'textBox' },
        { id: 7, name: 'ワクチン', type: 'selectBox' },
        { id: 8, name: '接種状況', type: 'periodRadioButton' },
        { id: 9, name: '接種期間', type: 'periodRadioButton' },
        { id: 10, name: '連絡状況', type: 'periodRadioButton' },
        { id: 11, name: '来院状況', type: 'periodRadioButton' },
        { id: 12, name: '予約状況', type: 'periodRadioButton' },
        { id: 13, name: 'ロット番号', type: 'textBox' }
      ],
      speciesSelectData: this.$store.getters['species/getDataIncludeDel'],
      vaccineSelectData: this.$store.getters['master/getData']('vaccines'),
      typeRadioData: [
        { id: 801, eachValue: 'あり', labelName: 'あり' },
        { id: 802, eachValue: 'なし', labelName: 'なし' }
      ],
      periodRadioData: [
        { id: 901, eachValue: 'あり', labelName: 'あり' },
        { id: 902, eachValue: 'なし', labelName: 'なし' }
      ],
      reminderRadioData: [
        { id: 1001, eachValue: 'あり', labelName: 'あり' },
        { id: 1002, eachValue: 'なし', labelName: 'なし' }
      ],
      comingRadioData: [
        { id: 1101, eachValue: 'あり', labelName: 'あり' },
        { id: 1102, eachValue: 'なし', labelName: 'なし' }
      ],
      reservationRadioData: [
        { id: 1201, eachValue: 'あり', labelName: 'あり' },
        { id: 1202, eachValue: 'なし', labelName: 'なし' }
      ],
      simpleSearchForms: [
        {
          id: 'vaccine',
          type: 'selectBox',
          label: 'ワクチン',
          selectData: this.$store.getters['master/selectVaccinesZero'],
          value: 0
        },
        {
          id: 'period',
          type: 'period',
          label: '接種状況',
          value: moment()
            .subtract(1, 'month')
            .format('YYYYMMDD'),
          value2: moment().format('YYYYMMDD')
        },
        {
          id: 'type',
          type: 'selectBox',
          selectData: [
            { id: 0, name: '全て' },
            { id: 1, name: '-' },
            { id: 2, name: '接種' },
            { id: 3, name: '抗体あり' },
            { id: 4, name: '抗体なし' },
            { id: 5, name: '抗体検査待' }
          ],
          adjacentFlg: true,
          value: 0
        }
      ],
      sortTypeId: 1,
      sortTypes: [
        { id: 1, name: '飼主ID順' },
        { id: 2, name: '飼主名順' },
        { id: 3, name: '患者ID順' },
        { id: 4, name: '患者名順' }
      ],
      orderTypeId: 1,
      orderTypes: [
        { id: 1, name: '昇順' },
        { id: 2, name: '降順' }
      ],
      hitAllDataCounts: 0,
      page: 0,
      isFull: false,
      popupFlg: false,
      popup: {
        type: '',
        title: '',
        message: '',
        buttons: ['閉じる']
      },
      mailPopupFlg: false,
      printPopupFlg: false,
      antibodiesPopupFlg: false,
      remindersPopupFlg: false,
      targetVaccinationSet: {},
      backReminderVaccinationSets: [],
      backRemindFlg: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      ownerById: 'owners/getDataById',
      patientById: 'patients/getDataById',
      masterDatumById: 'master/getDataById',
      speciesById: 'species/getDataById',
      medicalRecordsByPatientId: 'medicalRecords/getDataByPatientId',
      reservationsByPatientId: 'reservations/getPatientReservations',
      antibodiesByPatientId: 'antibodies/getDataByPatientId',
      antibodyTypesByPatientId: 'antibodyTypes/getDataByPatientId',
      vaccineRemindersByPatientId: 'vaccineReminders/getDataByPatientId',
      patientVaccinesByPatientId: 'patientVaccines/getDataByPatientId',
      patientVaccinePeriodsByPatientId:
        'patientVaccinePeriods/getDataByPatientId',
      selectVaccinesZero: 'master/selectVaccinesZero'
    }),
    checkedChecks() {
      return this.checks.filter(v => v.checkFlg && !v.disabled)
    },
    reminderVaccinationSets() {
      if (this.mailFlg || this.printFlg || this.csvFlg) {
        const front = this.vaccinationSets.flatMap(vaccinationSet => {
          const hasChecked = this.checkedChecks.some(
            check => check.patientId === vaccinationSet.patientId
          )
          if (!hasChecked) return []
          const patient = vaccinationSet.patient
          const owner = vaccinationSet.owner
          const previousComingDate = vaccinationSet.previousComingDate
          const nextReservationDate = vaccinationSet.nextReservationDate
          const reminderVaccinations = vaccinationSet.vaccinations.filter(
            vaccination => vaccination.matchFlg && vaccination.reminderFlg
          )
          if (reminderVaccinations.length === 0) return []
          const patientName = patient.name
          const ownerName = `${owner.lastName} ${owner.firstName}`
          const name = `${patientName}（${ownerName}）`
          return {
            patient,
            owner,
            name,
            previousComingDate,
            nextReservationDate,
            vaccinations: reminderVaccinations
          }
        })
        const back = this.backRemindFlg
          ? this.backReminderVaccinationSets.filter(v => {
              const owner = v.owner
              return this.mailFlg ? owner.email !== '' : true
            })
          : []
        const reminderVaccinationSets = front.concat(back)
        return reminderVaccinationSets
      } else {
        return []
      }
    }
  },

  watch: {
    sortTypeId() {
      this.resetAndGetData()
    },
    orderTypeId() {
      this.resetAndGetData()
    }
  },

  async created() {
    await this.search()
  },

  methods: {
    // データ読込
    async resetAndGetData() {
      this.vaccinationSets = []
      this.checks = []
      this.checkAll(true)
      this.page = 0
      this.isFull = false
      await this.search()
    },
    async getNextData() {
      if (!this.isFull && !this.waitFlg) await this.search()
    },
    async search() {
      this.waitFlg = true
      const res = this.detailSearchFlg
        ? await this.detailSearch()
        : await this.simpleSearch()
      this.waitFlg = false
      if (res.result === true) {
        this.page++
        this.hitAllDataCounts = res.hitAllDataCounts
        this.makeVaccinationSets(res.patientIds)
        this.backReminderVaccinationSets = res.reminderVaccinationSets
        if (this.mailFlg || this.printFlg || this.csvFlg) {
          this.makeChecks(this.vaccinationSets)
        }
        if (
          res.patientIds.length === 0 ||
          this.vaccinationSets.length < 100 ||
          this.vaccinationSets.length === this.hitAllDataCounts
        ) {
          this.isFull = true
        }
        if (this.page >= 10 && this.vaccinationSets.length >= 1000) {
          this.isFull = true
          this.popupFlg = true
          this.popup.type = 'alert'
          this.popup.title = '警告'
          this.popup.buttons = ['閉じる']
          this.popup.message =
            '予防接種情報の表示件数が上限の1000件に達しました。'
        }
      } else {
        this.popupFlg = true
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.buttons = ['閉じる']
        this.popup.message = '通信エラーが発生しました。'
      }
    },
    async detailSearch() {
      const conditions = this.searchConditions.map(v => {
        const isShowIdSearch = v.selectId === 1 || v.selectId === 3
        const text = isShowIdSearch
          ? toHanKakuId(v.text.toLowerCase().replace(/\s+/g, ''))
          : typeof v.text === 'string'
          ? v.text.toLowerCase().replace(/\s+/g, '')
          : v.text
        return {
          id: v.id,
          selectId: v.selectId,
          text,
          text2: v.text2,
          text3: v.text3
        }
      })
      const res = await this.$store.dispatch('vaccinations/detailSearch', {
        conditions,
        sortTypeId: this.sortTypeId,
        orderTypeId: this.orderTypeId,
        page: this.page
      })
      return res
    },
    async simpleSearch() {
      const vaccineId = this.formValue('vaccine', 'value')
      const periodStart = this.formValue('period', 'value')
      const periodEnd = this.formValue('period', 'value2')
      const typeId = this.formValue('type', 'value')
      const res = await this.$store.dispatch('vaccinations/simpleSearch', {
        vaccineId,
        periodStart,
        periodEnd,
        typeId,
        sortTypeId: this.sortTypeId,
        orderTypeId: this.orderTypeId,
        page: this.page
      })
      return res
    },
    formValue(formId, key) {
      const form = this.simpleSearchForms.find(v => v.id === formId)
      const value = form[key]
      return value
    },
    getMedicalRecords(patientId) {
      return this.medicalRecordsByPatientId(patientId) || []
    },
    getReservations(patientId) {
      return this.reservationsByPatientId(patientId)
    },
    getPatientVaccines(patientId) {
      return this.patientVaccinesByPatientId(patientId) || []
    },
    getPatientVaccinePeriods(patientId) {
      return this.patientVaccinePeriodsByPatientId(patientId) || []
    },
    getAntibodies(patientId) {
      return this.antibodiesByPatientId(patientId) || []
    },
    getAntibodyTypes(patientId) {
      return this.antibodyTypesByPatientId(patientId) || []
    },
    getVaccineReminders(patientId) {
      return this.vaccineRemindersByPatientId(patientId) || []
    },
    makeVaccinationSets(patientIds) {
      const addData = patientIds.map(patientId => {
        const patient = this.patientById(patientId)
        const vaccinationSet = this.makeVaccinationSet(patient)
        return vaccinationSet
      })
      this.vaccinationSets = this.vaccinationSets.concat(addData)
    },
    makeVaccinationSet(patient) {
      const patientId = patient.id
      const owner = this.ownerById(patient.ownerId)
      const previousComingDate = this.makePreviousComingDate(
        this.getMedicalRecords(patientId)
      )
      const nextReservationDate = this.makeNextReservationDate(
        this.getReservations(patientId)
      )
      const patientVaccinePeriods = this.getPatientVaccinePeriods(patientId)
      const antibodies = this.getAntibodies(patientId)
      const antibodyTypes = this.getAntibodyTypes(patientId)
      const vaccineReminders = this.getVaccineReminders(patientId)
        .slice()
        .sort((a, b) =>
          a.date === b.date ? a.id - b.id : a.date < b.date ? -1 : 1
        )
      const patientVaccines = this.getPatientVaccines(patientId)
      const vaccinations = this.getPatientVaccines(patientId).map(
        patientVaccine => {
          const vaccination = this.makeVaccination(
            patientVaccine,
            patientVaccinePeriods,
            antibodies,
            antibodyTypes,
            vaccineReminders
          )
          return vaccination
        }
      )
      const vaccinationSet = {
        patientId,
        owner,
        patient,
        previousComingDate,
        nextReservationDate,
        patientVaccines,
        antibodies,
        antibodyTypes,
        vaccineReminders,
        vaccinations
      }
      return vaccinationSet
    },
    getNearDatum(data, type) {
      const isEarly = type === 'earliest'
      return data.length > 0
        ? data.reduce((a, b) => {
            const compareId = isEarly ? a.id < b.id : a.id > b.id
            const compareTime = isEarly
              ? a.startTime < b.startTime
              : a.startTime > b.startTime
            const compareDate = isEarly ? a.date < b.date : a.date > b.date
            return a.date === b.date
              ? a.startTime === b.startTime
                ? compareId
                  ? a
                  : b
                : compareTime
                ? a
                : b
              : compareDate
              ? a
              : b
          })
        : undefined
    },
    makePreviousComingDate(medicalRecordsPerPatient) {
      const nowDateTime = moment().format('YYYYMMDDHHmm')
      const pastMedicalRecords = medicalRecordsPerPatient.filter(v => {
        const startDateTime = v.date + v.startTime
        return startDateTime <= nowDateTime && v.delFlg === 0
      })
      const latestMedicalRecord = this.getNearDatum(
        pastMedicalRecords,
        'latest'
      )
      return latestMedicalRecord?.date || ''
    },
    makeNextReservationDate(reservationsPerPatient) {
      const nowDateTime = moment().format('YYYYMMDDHHmm')
      const futureReservations = reservationsPerPatient.filter(v => {
        const startDateTime = v.date + v.startTime
        return (
          nowDateTime < startDateTime && v.cancelFlg === 0 && v.delFlg === 0
        )
      })
      const earliestReservation = this.getNearDatum(
        futureReservations,
        'earliest'
      )
      return earliestReservation?.date || ''
    },
    makeVaccination(
      patientVaccine,
      patientVaccinePeriodsPerPatient,
      antibodiesPerPatient,
      antibodyTypesPerPatient,
      vaccineRemindersPerPatient
    ) {
      const patientVaccineId = patientVaccine.id
      const vaccineId = patientVaccine.vaccineId
      const periods = this.makePeriods(
        vaccineId,
        patientVaccinePeriodsPerPatient
      )
      const currentPeriod = periods.find(v => v.currentFlg)
      const nextPeriod = periods.find(v => v.nextFlg)
      const dateAntibodyTypes = this.makeDateAntibodyTypes(
        vaccineId,
        antibodiesPerPatient,
        antibodyTypesPerPatient
      )
      const previousDateAntibodyType =
        dateAntibodyTypes[dateAntibodyTypes.length - 1]
      const completeFlg = this.hasComplete(currentPeriod, dateAntibodyTypes)
      const reminders = this.makeReminders(
        vaccineId,
        vaccineRemindersPerPatient
      )
      const previousVaccineReminder = reminders[reminders.length - 1]
      const reminderFlg = this.makeReminderFlg(
        currentPeriod,
        nextPeriod,
        completeFlg
      )
      const periodForMail = this.makePeriodForMail(
        reminderFlg,
        currentPeriod,
        nextPeriod
      )
      const base = {
        patientVaccineId,
        vaccineId,
        previousDateAntibodyType,
        currentPeriod,
        completeFlg,
        nextPeriod,
        previousVaccineReminder,
        dateAntibodyTypes,
        periods,
        reminders,
        reminderFlg,
        periodForMail
      }
      const matchFlg = this.detailSearchFlg
        ? this.isMatchedAtDetail(base)
        : this.isMatchedAtSimple(base)
      const vaccination = { ...base, matchFlg }
      return vaccination
    },
    makePeriods(vaccineId, patientVaccinePeriods) {
      const vaccinePeriods = patientVaccinePeriods.filter(
        patientVaccinePeriod => patientVaccinePeriod.vaccineId === vaccineId
      )
      const sortedPeriods = vaccinePeriods.sort((a, b) =>
        a.startDate < b.startDate ? -1 : 1
      ) // 昇順
      const repeatPeriods = makeRepeatPeriods(sortedPeriods)
      const today = moment().format('YYYYMMDD')
      let hasCurrent = false
      let hasNext = false
      const periods = []
      repeatPeriods.forEach((repeatPeriod, i, array) => {
        if (hasNext) return
        const nextPeriod = array[i + 1]
        const startDate = repeatPeriod.startDate
        const endDate =
          repeatPeriod.endDate === '' && nextPeriod
            ? moment(nextPeriod.startDate, 'YYYYMMDD')
                .add(-1, 'd')
                .format('YYYYMMDD')
            : repeatPeriod.endDate
        if (startDate <= today && (today <= endDate || endDate === '')) {
          hasCurrent = true
        }
        if (today < startDate) hasNext = true
        const period = {
          startDate,
          endDate,
          currentFlg: hasCurrent && !hasNext,
          nextFlg: hasNext
        }
        periods.push(period)
      })
      return periods
    },
    makeDateAntibodyTypes(
      vaccineId,
      antibodiesPerPatient,
      antibodyTypesPerPatient
    ) {
      const today = moment().format('YYYYMMDD')
      const dateAntibodyTypes = antibodyTypesPerPatient
        .flatMap(antibodyType => {
          const isValid =
            antibodyType.vaccineId === vaccineId &&
            antibodyType.type !== '' &&
            antibodyType.delFlg === 0
          if (!isValid) return []
          const antibody = antibodiesPerPatient.find(
            antibody => antibody.id === antibodyType.antibodyId
          )
          if (!antibody) return []
          const date = antibody.date
          if (today < date) return []
          const type = antibodyType.type
          return { date, type, lotNo: antibodyType.lotNo }
        })
        .sort((a, b) =>
          a.date === b.date ? a.id - b.id : a.date < b.date ? -1 : 1
        ) // 昇順
      return dateAntibodyTypes
    },
    hasComplete(currentPeriod, dateAntibodyTypes) {
      if (!currentPeriod) return false
      const today = moment().format('YYYYMMDD')
      const start = currentPeriod.startDate
      const end = currentPeriod.endDate || today
      const complete = dateAntibodyTypes.some(dateAntibodyType => {
        const { date, type } = dateAntibodyType
        const inPeriod = start <= date && date <= end
        const isCompleteType = type === 'vaccinated' || type === 'positive'
        return inPeriod && isCompleteType
      })
      return complete
    },
    makeReminders(vaccineId, vaccineRemindersPerPatient) {
      const reminders = vaccineRemindersPerPatient.filter(v => {
        const vaccineIds = v.vaccineIds
        const hasVaccine = vaccineIds.includes(vaccineId)
        return hasVaccine
      })
      return reminders
    },
    makeReminderFlg(currentPeriod, nextPeriod, completeFlg) {
      const isCurrentRequired = currentPeriod && !completeFlg ? true : false
      const isNextRequired = !currentPeriod && nextPeriod ? true : false
      const isReminderRequired = isCurrentRequired || isNextRequired
      return isReminderRequired
    },
    makePeriodForMail(reminderFlg, currentPeriod, nextPeriod) {
      const period =
        reminderFlg && currentPeriod
          ? currentPeriod
          : reminderFlg && !currentPeriod && nextPeriod
          ? nextPeriod
          : undefined
      return period
        ? { startDate: period.startDate, endDate: period.endDate }
        : undefined
    },
    // 検索
    toggleSearch() {
      this.detailSearchFlg = !this.detailSearchFlg
      this.searchConditions = [
        { id: 1, selectId: 0, text: '', text2: '', text3: '' }
      ]
      this.simpleSearchForms.forEach(form => {
        if (form.type === 'selectBox') {
          this.$set(form, 'value', 0)
        } else if (form.type === 'period') {
          this.$set(form, 'value', this.defaultPeriodStart)
          this.$set(form, 'value2', this.defaultPeriodEnd)
        }
      })
    },
    // 詳細検索
    setSelectId(idIndex) {
      const target = this.searchConditions[idIndex.index]
      target.selectId = idIndex.id
      target.text = ''
      target.text2 = ''
      target.text3 = ''
      if (idIndex.id === 5) {
        this.$set(target, 'selectData', this.speciesSelectData)
        target.text = this.speciesSelectData[0].id
      }
      if (idIndex.id === 7) {
        this.$set(target, 'selectData', this.vaccineSelectData)
        target.text = this.vaccineSelectData[0].id
      }
      if (idIndex.id === 8) {
        this.$set(target, 'label', '接種/抗体')
        this.$set(target, 'radioButtonData', this.typeRadioData)
        this.$set(target, 'text', this.defaultPeriodStart)
        this.$set(target, 'text2', this.defaultPeriodEnd)
        this.$set(target, 'text3', 'あり')
      }
      if (idIndex.id === 9) {
        this.$set(target, 'label', '接種期間')
        this.$set(target, 'radioButtonData', this.periodRadioData)
        this.$set(target, 'text', this.defaultPeriodStart)
        this.$set(target, 'text2', this.defaultPeriodEnd)
        this.$set(target, 'text3', 'あり')
      }
      if (idIndex.id === 10) {
        this.$set(target, 'label', '連絡')
        this.$set(target, 'radioButtonData', this.reminderRadioData)
        this.$set(target, 'text', this.defaultPeriodStart)
        this.$set(target, 'text2', this.defaultPeriodEnd)
        this.$set(target, 'text3', 'あり')
      }
      if (idIndex.id === 11) {
        this.$set(target, 'label', '来院')
        this.$set(target, 'radioButtonData', this.comingRadioData)
        this.$set(target, 'text', this.defaultPeriodStart)
        this.$set(target, 'text2', this.defaultPeriodEnd)
        this.$set(target, 'text3', 'あり')
      }
      if (idIndex.id === 12) {
        this.$set(target, 'label', '予約')
        this.$set(target, 'radioButtonData', this.reservationRadioData)
        this.$set(target, 'text', this.defaultPeriodStart)
        this.$set(target, 'text2', this.defaultPeriodEnd)
        this.$set(target, 'text3', 'あり')
      }
      if (idIndex.id === 14) {
        this.$set(target, 'text', [])
        this.$set(target, 'text2', false)
      }
      this.$set(this.searchConditions, idIndex.index, target)
    },
    detailSearchInput(textObj, key) {
      const target = this.searchConditions[textObj.index]
      const text = textObj.text
      this.$set(target, key, text)
      this.$set(this.searchConditions, textObj.index, target)
    },
    detailSearchClear(clearObj) {
      const target = this.searchConditions[clearObj.index]
      const key = clearObj.key
      this.$set(target, key, '')
    },
    selectOwnerTag(ownerTagId, index) {
      const target = this.searchConditions[index]
      const oldOwnerTagIds = target.text
      const newOwnerTagIds = oldOwnerTagIds.includes(ownerTagId)
        ? oldOwnerTagIds.filter(v => v !== ownerTagId)
        : [...oldOwnerTagIds, ownerTagId]
      const condition = { ...target, text: newOwnerTagIds }
      this.$set(this.searchConditions, index, condition)
    },
    toggleOwnerTagSearchOption(index, boolean) {
      const target = this.searchConditions[index]
      const condition = { ...target, text2: boolean }
      this.$set(this.searchConditions, index, condition)
    },
    addSearchCondition() {
      const id = this.searchConditions[this.searchConditions.length - 1].id + 1
      this.searchConditions.push({
        id,
        selectId: 0,
        text: '',
        text2: '',
        text3: ''
      })
    },
    deleteSearchCondition(id) {
      this.searchConditions = this.searchConditions.filter(v => v.id !== id)
    },
    isMatchedAtDetail(vaccination) {
      const vaccinationConditionIds = new Set([7, 8, 9, 10, 13])
      const vaccinationConditions = this.searchConditions.filter(v =>
        vaccinationConditionIds.has(v.selectId)
      )
      const filterResults = vaccinationConditions.reduce(
        (results, searchCondition) => {
          const result = this.filterByVaccinationCondition(
            vaccination,
            searchCondition
          )
          results.push(result)
          return results
        },
        []
      )
      const isMatched = filterResults.every(v => v)
      return isMatched
    },
    filterByVaccinationCondition(vaccination, searchCondition) {
      const periodRadioArgs = [
        vaccination,
        searchCondition.text,
        searchCondition.text2,
        searchCondition.text3
      ]
      const selectId = searchCondition.selectId
      const isMatched =
        selectId === 7
          ? this.filterByVaccine(vaccination, searchCondition.text)
          : selectId === 8
          ? this.filterByCompleted(...periodRadioArgs)
          : selectId === 9
          ? this.filterByPeriod(...periodRadioArgs)
          : selectId === 10
          ? this.filterByReminder(...periodRadioArgs)
          : selectId === 13
          ? this.filterByLotNumber(vaccination, searchCondition.text)
          : true
      return isMatched
    },
    filterByCompleted(
      vaccination,
      searchPeriodStart,
      searchPeriodEnd,
      searchRadioValue
    ) {
      const start = searchPeriodStart || '00000000'
      const end = searchPeriodEnd || moment().format('YYYYMMDD')
      const dateAntibodyTypes = vaccination.dateAntibodyTypes
      const hasCompleted = dateAntibodyTypes.some(dateAntibodyType => {
        const type = dateAntibodyType.type
        const isCompletedType = type === 'vaccinated' || type === 'positive'
        const date = dateAntibodyType.date
        const isWithinPeriod = start <= date && date <= end
        return isCompletedType && isWithinPeriod
      })
      const hasFlg = searchRadioValue === 'あり'
      const isMatched = hasFlg ? hasCompleted : !hasCompleted
      return isMatched
    },
    filterByPeriod(
      vaccination,
      searchPeriodStart,
      searchPeriodEnd,
      searchRadioValue
    ) {
      const searchPeriod = {
        start: searchPeriodStart || '00000000',
        end: searchPeriodEnd || '99999999'
      }
      const periods = vaccination.periods
      const hasOverlapped = periods.some(period => {
        const vaccinePeriod = { start: period.startDate, end: period.endDate }
        const isOverlapped = this.isOverlappedPeriods(
          searchPeriod,
          vaccinePeriod
        )
        return isOverlapped
      })
      const hasFlg = searchRadioValue === 'あり'
      const isMatched = hasFlg ? hasOverlapped : !hasOverlapped
      return isMatched
    },
    filterByReminder(
      vaccination,
      searchPeriodStart,
      searchPeriodEnd,
      searchRadioValue
    ) {
      const start = searchPeriodStart || '00000000'
      const end = searchPeriodEnd || moment().format('YYYYMMDD')
      const reminders = vaccination.reminders
      const hasReminder = reminders.some(reminder => {
        const date = reminder.date
        const isWithinPeriod = start <= date && date <= end
        return isWithinPeriod
      })
      const hasFlg = searchRadioValue === 'あり'
      const isMatched = hasFlg ? hasReminder : !hasReminder
      return isMatched
    },
    filterByLotNumber(vaccination, searchLotNumber) {
      const { dateAntibodyTypes } = vaccination
      const isMatched = dateAntibodyTypes.some(
        dateAntibodyType =>
          dateAntibodyType.type === 'vaccinated' &&
          dateAntibodyType.lotNo === searchLotNumber
      )
      return isMatched
    },
    isOverlappedPeriods(periodA, periodB) {
      const isInvalid = period => {
        if (!period) return true
        const isNoValue = period.start === '' && period.end === ''
        const isReversed =
          period.start !== '' && period.end !== '' && period.start > period.end
        const isInvalid = isNoValue || isReversed
        return isInvalid
      }
      if (isInvalid(periodA) || isInvalid(periodB)) return false
      const startA = periodA.start === '' ? '00000000' : periodA.start
      const endA = periodA.end === '' ? '99999999' : periodA.end
      const startB = periodB.start === '' ? '00000000' : periodB.start
      const endB = periodB.end === '' ? '99999999' : periodB.end
      return startB <= endA && startA <= endB
    },
    // 簡易検索
    simpleSearchInput(inputObj) {
      const formId = inputObj.id
      const key = inputObj.key
      const value = inputObj.value
      const targetForm = this.simpleSearchForms.find(v => v.id === formId)
      this.$set(targetForm, key, value)
    },
    simpleSearchClear(clearObj) {
      const id = clearObj.id
      const key = clearObj.key
      const targetForm = this.simpleSearchForms.find(v => v.id === id)
      this.$set(targetForm, key, '')
    },
    isMatchedAtSimple(vaccination) {
      const searchVaccineId = this.formValue('vaccine', 'value')
      const searchPeriodStart = this.formValue('period', 'value')
      const searchPeriodEnd = this.formValue('period', 'value2')
      const searchAntibodyTypeId = this.formValue('type', 'value')
      const isMatched =
        this.filterByVaccine(vaccination, searchVaccineId) &&
        this.filterByType(
          vaccination,
          searchPeriodStart,
          searchPeriodEnd,
          searchAntibodyTypeId
        )
      return isMatched
    },
    filterByVaccine(vaccination, searchVaccineId) {
      return searchVaccineId > 0
        ? vaccination.vaccineId === searchVaccineId
        : true
    },
    filterByType(
      vaccination,
      searchPeriodStart,
      searchPeriodEnd,
      searchAntibodyTypeId
    ) {
      const start = searchPeriodStart || '00000000'
      const end = searchPeriodEnd || moment().format('YYYYMMDD')
      const dateAntibodyTypes = vaccination.dateAntibodyTypes
      const dateAntibodyTypesWithinPeriod = dateAntibodyTypes.filter(
        dateAntibodyType => {
          const date = dateAntibodyType.date
          return start <= date && date <= end
        }
      )
      const hasType = (type, dateAntibodyTypes) =>
        dateAntibodyTypes.some(
          dateAntibodyType => dateAntibodyType.type === type
        )
      return searchAntibodyTypeId === 0
        ? true
        : searchAntibodyTypeId === 1
        ? dateAntibodyTypesWithinPeriod.length === 0
        : searchAntibodyTypeId === 2
        ? hasType('vaccinated', dateAntibodyTypesWithinPeriod)
        : searchAntibodyTypeId === 3
        ? hasType('positive', dateAntibodyTypesWithinPeriod)
        : searchAntibodyTypeId === 4
        ? hasType('negative', dateAntibodyTypesWithinPeriod)
        : searchAntibodyTypeId === 5
        ? hasType('waiting', dateAntibodyTypesWithinPeriod)
        : true
    },
    // ボタン, ポップアップ
    async mailUpdated() {
      this.mailPopupFlg = false
      this.mailFlg = false
      await this.resetAndGetData()
    },
    async printUpdated() {
      this.printPopupFlg = false
      this.printFlg = false
      await this.resetAndGetData()
    },
    goToOwnerPage(ownerId, patientId) {
      this.$router.push({
        name: 'owner',
        params: { id: ownerId },
        hash: `#patient-${patientId}`
      })
    },
    openAntibodiesPopup(patientId) {
      const vaccinationSet = this.vaccinationSets.find(
        v => v.patientId === patientId
      )
      this.targetVaccinationSet = { ...vaccinationSet }
      this.antibodiesPopupFlg = true
    },
    async antibodyUpdated() {
      this.antibodiesPopupFlg = false
      await this.resetAndGetData()
    },
    openRemindersPopup(patientId) {
      const vaccinationSet = this.vaccinationSets.find(
        v => v.patientId === patientId
      )
      this.targetVaccinationSet = { ...vaccinationSet }
      this.remindersPopupFlg = true
    },
    async reminderUpdated() {
      this.remindersPopupFlg = false
      await this.resetAndGetData()
    },
    // チェック
    toMailMode() {
      this.mailFlg = true
      this.makeChecks(this.vaccinationSets)
      this.checkAll(true)
    },
    toPrintMode() {
      this.printFlg = true
      this.makeChecks(this.vaccinationSets)
      this.checkAll(true)
    },
    toCsvMode() {
      this.csvFlg = true
      this.makeChecks(this.vaccinationSets)
      this.checkAll(true)
    },
    createCsv() {
      const headerStr =
        '飼主ID,飼主名,患者ID,患者名,種別,品種,年齢,生年月日,電話番号,郵便番号,住所,前回来院,次回予約,ワクチン,前回の接種/抗体検査,現在の接種期間,次回の接種期間,前回の連絡\n'
      let bodyStr = ''
      for (let vaccinationSet of this.reminderVaccinationSets) {
        const ownerPatientStrs = [
          vaccinationSet.owner.showId,
          vaccinationSet.owner.lastName + ' ' + vaccinationSet.owner.firstName,
          vaccinationSet.patient.showId,
          vaccinationSet.patient.name,
          this.speciesById(vaccinationSet.patient.speciesId).name,
          vaccinationSet.patient.breed,
          calcAge(vaccinationSet.patient),
          this.toDate(makeBirthday(vaccinationSet.patient)),
          vaccinationSet.owner.tel,
          vaccinationSet.owner.postalCode,
          this.masterDatumById('prefectures', vaccinationSet.owner.prefectureId)
            ?.name +
            vaccinationSet.owner.address +
            vaccinationSet.owner.building,
          this.toDate(vaccinationSet.previousComingDate),
          this.toDate(vaccinationSet.nextReservationDate)
        ]
        for (let vaccination of vaccinationSet.vaccinations) {
          const vaccinationStrs = [
            this.toVaccineName(vaccination.vaccineId),
            this.toDateType(vaccination.previousDateAntibodyType),
            this.toPeriod(vaccination.currentPeriod, '期間外'),
            this.toPeriod(vaccination.nextPeriod, '未設定'),
            this.toDateMedia(vaccination.previousVaccineReminder)
          ]
          const rowStrs = ownerPatientStrs.concat(vaccinationStrs)
          bodyStr += rowStrs.join() + '\n'
        }
      }
      const csvStr = headerStr + bodyStr
      const fileName = `vaccinations`
      return { csvStr, fileName }
    },
    download() {
      const { csvStr, fileName } = this.createCsv()
      downloadCsv(csvStr, fileName)
    },
    toVaccineName(vaccineId) {
      const vaccine = this.masterDatumById('vaccines', vaccineId)
      return vaccine.name
    },
    toDateType(dateAntibodyType) {
      if (!dateAntibodyType) return 'なし'
      const date = this.toDate(dateAntibodyType.date)
      const jpType = antibodyTypesEnToJp[dateAntibodyType.type]
      return `${date} ${jpType}`
    },
    toDate(date) {
      return date ? moment(date, 'YYYYMMDD').format('YYYY/MM/DD') : ''
    },
    toPeriod(period, text) {
      if (!period) return text || ''
      const start = this.toDate(period.startDate)
      const end = this.toDate(period.endDate)
      return `${start} ～ ${end}`
    },
    toDateMedia(vaccineReminder) {
      if (!vaccineReminder) return 'なし'
      const date = this.toDate(vaccineReminder.date)
      const jpMedia = mediaEnToJp[vaccineReminder.media]
      return `${date} ${jpMedia}`
    },
    makeChecks(vaccinationSets) {
      const checks = vaccinationSets.map(vaccinationSet => {
        const patientId = vaccinationSet.patientId
        const email = vaccinationSet.owner.email
        const sameCheck = this.checks.find(
          check => check.patientId === patientId
        )
        const reminderRequired = vaccinationSet.vaccinations.some(
          vaccination => vaccination.matchFlg && vaccination.reminderFlg
        )
        const disabled = this.mailFlg
          ? !email || !reminderRequired
          : !reminderRequired
        const checkFlg = disabled
          ? false
          : sameCheck
          ? sameCheck.checkFlg
          : true
        return { patientId, checkFlg, disabled }
      })
      this.checks = checks
      this.confirmAllFlg()
    },
    confirmAllFlg() {
      const hasUnCheck = this.checks.some(
        check => !check.disabled && !check.checkFlg
      )
      if (hasUnCheck) this.allFlg = false
    },
    checkAll(flg) {
      this.allFlg = flg
      this.backRemindFlg = flg
      this.checks = this.checks.map(v => {
        return { ...v, checkFlg: v.disabled ? false : flg }
      })
    },
    check(patientId, flg) {
      const target = this.checks.find(check => check.patientId === patientId)
      target.checkFlg = flg
      this.confirmAllFlg()
    }
  }
}
</script>

<style lang="scss" scoped>
.vaccination-sets {
  display: flex;
  width: 100%;
  overflow: auto;
  text-align: left;
  > .wrapper {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 1250px;
    min-height: 800px;
    padding: 24px 48px;
    > .title {
      box-sizing: border-box;
      height: 50px;
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .contents {
      flex: 1;
      height: 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      > .bulk {
        margin-top: 20px;
        > .buttons {
          display: flex;
          justify-content: flex-end;
          > .button ~ .button {
            margin-left: 20px;
          }
        }
      }
      > .search {
        margin-top: 20px;
      }
      > .counts-sort {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        > .left {
          display: flex;
          align-items: center;
          > .checkbox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
          }
          > .counts {
            margin-left: 5px;
          }
        }
        > .right {
          display: flex;
          align-items: center;
          > .select-box {
            margin-left: 10px;
          }
        }
      }
      > .list {
        margin-top: 15px;
        flex: 1;
        height: 0px;
      }
    }
  }
}
</style>
